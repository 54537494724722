/* eslint-disable max-lines */
import { AnalyticUtils, Main } from 'wikr-core-analytics';
import Solid from 'solid-payment';
import { SENTRY_PAYMENT, ERROR_LEVELS } from 'sentry-utils';
import { takeLatest, put, select, call } from 'redux-saga/effects';

import config from 'config';

import api from 'api';

import { selectDefaultAmazonAnalyticData, selectUserId } from 'redux/User/selectors';
import { showModal } from 'redux/UiEffects/actions';
import { selectFlowLink, selectTestaniaName } from 'redux/Testania/selectors';
import { RootState } from 'redux/rootReducer';
import {
    selectPaymentMethod,
    selectCurrentProduct,
    selectCurrency,
    selectCheckoutOrderId,
    selectCurrentProductCode,
    selectOneClickPaymentPrice,
    selectUpsellId,
} from 'redux/Payment/selectors';
import * as actionTypes from 'redux/Payment/actionTypes';
import { initOneClickPayment as initOneClickPaymentAction } from 'redux/Payment/actions';

import { PAYMENT_TYPES_NAME, PAYMENT_TYPES_NAME_ID } from 'constants/payments';

import Yahoo from 'services/Yahoo';
import sentry from 'services/Sentry/SentryInstance';

import {
    cleanObject,
    getPriceFromCents,
    generateQueryParams,
    deleteSlash,
    fromPennyToInt,
    replaceDataWithLocalhost,
} from 'helpers/utils';
import { getPaymentIdByMethod, isSubscription, isLifetime, calculateLTV } from 'helpers/payment/utils';

import { DefaultAmazonPurchaseDataType, PostInitResponse, ValidateResponse } from 'types/payments/validate';
import { CurrentProduct, Currency, ToNextPagePayload, PaymentMethod, ProductCode } from 'types/payments/payments';
import { PaymentData } from 'types/payments/paymentApiInterfaces';
import { AnalyticPurchasePayload, IValidateRecurringPayment } from 'types/payments/oneClickPayment';
import { Awaited } from 'types/commonInterfaces';

const getOneClickPrice = (state: RootState) => selectOneClickPaymentPrice(state);
const getCurrency = (state: RootState) => selectCurrency(state);
const getOrderId = (state: RootState) => selectCheckoutOrderId(state);
const getPaymentMethod = (state: RootState) => selectPaymentMethod(state);
const getUserId = (state: RootState) => selectUserId(state);
const getCurrentProductCode = (state: RootState) => selectCurrentProductCode(state);
const getCurrentProduct = (state: RootState) => selectCurrentProduct(state);
const getFlowLink = (state: RootState) => selectFlowLink(state);
const getTestaniaName = (state: RootState) => selectTestaniaName(state);
const getUpsellId = (state: RootState) => selectUpsellId(state);
const getDefaultAmazonPurchaseData = (state: RootState) => selectDefaultAmazonAnalyticData(state);

function* init({ payload }: ReturnType<typeof initOneClickPaymentAction>) {
    const oneClickPrice: number = yield select(getOneClickPrice);
    const checkoutOrderId: number = yield select(getOrderId);
    const currentProduct: CurrentProduct = yield select(getCurrentProduct);
    const paymentMethod: PaymentMethod = yield select(getPaymentMethod);
    const currentProductCode: ProductCode = yield select(getCurrentProductCode);
    const currency: Currency = yield select(getCurrency);
    const userId: number = yield select(getUserId);

    const paymentType = currentProduct?.payment_type;
    const paymentSystem: string = PAYMENT_TYPES_NAME_ID[paymentMethod];

    try {
        yield put({ type: actionTypes.SET_LOADING_STATUS, payload: true });

        const meta = {
            product_code: currentProductCode,
            ...(isLifetime(paymentType) && { price: oneClickPrice }),
            ...(isSubscription(paymentType) && { product_id: currentProduct?.id }),
        };

        // @ts-ignore
        const upsellResponse: PaymentData = yield Solid.oneClickPayment(paymentSystem, meta);

        const responseOrder = upsellResponse?.order;

        const paymentId =
            isSubscription(paymentType) && responseOrder?.subscription_id
                ? responseOrder?.subscription_id
                : responseOrder?.order_id;

        const payloadMeta = {
            currentProductCode,
            checkoutOrderId,
            paymentMethod,
            currentProduct,
            oneClickPrice,
            currency,
            userId,
        };
        const validateMeta = {
            paymentId,
            payload,
            orderId: responseOrder?.order_id,
            subscriptionId: responseOrder?.subscription_id || '',
        };

        yield put({ type: actionTypes.SET_PAYMENT_DATA, payload: upsellResponse });
        yield call(validateRecurringPayment, { validateMeta, payloadMeta });
    } catch ({ error }) {
        console.error('init oneClickPayment error', error);

        sentry.logError(error, SENTRY_PAYMENT, ERROR_LEVELS.CRITICAL, {
            ...currentProduct,
            ...payload
        });

        yield call(moveToNextPage, true, payload);
        yield call(errorHandler);
    } finally {
        yield put({ type: actionTypes.SET_LOADING_STATUS, payload: false });
    }
}

function* validateRecurringPayment({ validateMeta, payloadMeta }: IValidateRecurringPayment) {
    const currentProductCode = payloadMeta.currentProductCode;
    const checkoutOrderId: number = payloadMeta.checkoutOrderId;
    const paymentMethod: PaymentMethod = payloadMeta.paymentMethod;
    const currentProduct: CurrentProduct = payloadMeta.currentProduct;

    const flowLink: string = yield select(getFlowLink);
    const testaniaName: string = yield select(getTestaniaName);
    const defaultAmazonPurchaseData: DefaultAmazonPurchaseDataType = yield select(getDefaultAmazonPurchaseData);

    const paymentMethodId: number = getPaymentIdByMethod(paymentMethod);
    const ltvValue = calculateLTV(currentProduct?.ltv, currentProduct?.period);
    const paymentMethodName: string | null = PAYMENT_TYPES_NAME[paymentMethod] || null;

    const paymentType = currentProduct?.payment_type;
    const isSubscriptionProduct = isSubscription(paymentType);
    const isLifetimeProduct = isLifetime(paymentType);

    const otherAnalyticData: Awaited<ReturnType<typeof AnalyticUtils.getAnalyticData>> = yield call(
        AnalyticUtils.getAnalyticData,
        'purchase'
    );
    const analyticProductName = isLifetimeProduct ? ` ${currentProduct?.name}` : '';

    const amountValue: number = fromPennyToInt(payloadMeta.oneClickPrice);

    const meta = {
        parent_order_id: checkoutOrderId, // order id from checkout page
        payment_type: paymentType,
        payment_method: paymentMethodId,
        product_code: currentProductCode,
        order_id: validateMeta.orderId,
        subscription_id: validateMeta.subscriptionId,
        flow_link: flowLink,
        ab_test_name: testaniaName,
        analytic_data: {
            currency: payloadMeta?.currency?.name,
            value: ltvValue,
            content_id: `Product - Price ${amountValue}${analyticProductName}`,
            price: amountValue,
            payment: paymentMethodName,
            order_id: validateMeta?.orderId,
            tariff: isSubscription(paymentType) ? currentProduct?.id : 'lifetime',
            order_type: currentProduct?.payment_type,
            mode: config.ENV,
            release_date: config.RELEASE_DATE,
            card_type: validateMeta.payload.data?.brand,
            aws_id: defaultAmazonPurchaseData.aws_id,
            screen_id: validateMeta?.payload?.eventName || 'upsale',
            ...(isSubscription(paymentType) && {
                subscription_id: validateMeta?.subscriptionId,
                subscription_price: amountValue,
            }),
            ...otherAnalyticData,
        },
        payment_screen: deleteSlash(validateMeta?.payload?.screenId),
        ...(isSubscriptionProduct && { product_id: currentProduct?.id }),
        ...(isSubscriptionProduct && currentProduct?.period && { subscription_period: currentProduct.period }),
        ...(isSubscriptionProduct && currentProduct?.trial && { subscription_trial_period: currentProduct.trial }),
    };

    const isLocalEnv = import.meta.env.DEV;

    // fix of local problem with SSRF
    isLocalEnv && replaceDataWithLocalhost(meta);

    try {
        const response: ValidateResponse & PostInitResponse = yield api.payment.postInit(meta);

        const isPendingStatus = response.is_pending;

        if (response.error) {
            yield call(showErrorModal, true);
            yield call(setUpsellId, currentProductCode);
        }

        const data: AnalyticPurchasePayload = {
            value: ltvValue,
            order_id: validateMeta.orderId,
            paymentMethodId,
            // TODO: is not written to validateMeta, maybe it should be removed.
            cardBrand: validateMeta?.payload?.data?.brand,
            customId: validateMeta?.payload?.eventName,
            paymentType,
            oneClickPrice: payloadMeta.oneClickPrice,
            tariff: payloadMeta.currentProduct?.id,
            subscriptionId: validateMeta.paymentId,
        };

        if (!response.error) {
            yield call(isPendingStatus ? sendAnalyticPurchasePending : sendAnalyticPurchase, data);
            yield put({ type: actionTypes.VALIDATE_PAYMENT_DATA, payload: { ...response, result: true } });
            yield call(setUpsellId, currentProductCode);
            yield call(moveToNextPage, true, validateMeta.payload);
        }
    } catch ({ error }) {
        console.error('validate oneClickPayment error', error);

        sentry.logError(error, SENTRY_PAYMENT, ERROR_LEVELS.CRITICAL, {
            ...currentProduct,
            ...meta
        });

        yield call(moveToNextPage, true, validateMeta.payload);
        yield call(errorHandler);
    }
}

function* sendAnalyticPurchase(payload: AnalyticPurchasePayload) {
    try {
        const currency: Currency = yield select(getCurrency);
        const userId: number = yield select(getUserId);
        const upsellId: string = yield select(getUpsellId);
        const paymentMethod: PaymentMethod = yield select(getPaymentMethod);

        const cents: number = payload.oneClickPrice;
        const price: number = getPriceFromCents(cents);
        const paymentMethodName: string | null = PAYMENT_TYPES_NAME[paymentMethod] || null;

        const abTestName: string | null = localStorage.getItem('testania_name');
        const urlParams: string = generateQueryParams();

        const { value, order_id, cardBrand, tariff, subscriptionId, paymentType } = payload;

        localStorage.setItem('paymentType', paymentType || 'lifetime');

        const analyticProductName = isLifetime(paymentType) ? ` ${upsellId}` : '';

        const analyticData = {
            currency: currency.name,
            value,
            content_id: `Product - Price ${price}${analyticProductName}`,
            tariff: isSubscription(paymentType) ? tariff : 'lifetime',
            price,
            payment: paymentMethodName,
            card_type: cardBrand,
            order_id: order_id,
            screen_id: payload?.customId || 'upsale',
            ab_test_name: abTestName,
            user_id: userId,
            urlParams,
            order_type: paymentType,
            ...(isSubscription(paymentType) && {
                subscription_id: subscriptionId,
                subscription_price: price,
            }),
        };

        Main.purchase(cleanObject(analyticData));
        Yahoo.push({ et: 'custom', ea: 'purchase', gv: String(analyticData.value) });
    } catch (e) {
        console.error('sendAnalyticPurchase', e);
    }
}

function* sendAnalyticPurchasePending(payload: AnalyticPurchasePayload) {
    try {
        const currency: Currency = yield select(getCurrency);
        const userId: number = yield select(getUserId);
        const upsellId: string = yield select(getUpsellId);
        const paymentMethod: PaymentMethod = yield select(getPaymentMethod);

        const cents: number = payload.oneClickPrice;
        const price: number = getPriceFromCents(cents);
        const paymentMethodName: string | null = PAYMENT_TYPES_NAME[paymentMethod] || null;

        const abTestName: string | null = localStorage.getItem('testania_name');
        const urlParams: string = generateQueryParams();

        const { value, order_id, cardBrand, tariff, subscriptionId, paymentType } = payload;

        localStorage.setItem('paymentType', paymentType || 'lifetime');

        const analyticProductName = isLifetime(paymentType) ? ` ${upsellId}` : '';

        const analyticData = {
            currency: currency.name,
            value,
            content_id: `Product - Price ${price}${analyticProductName}`,
            tariff: isSubscription(paymentType) ? tariff : 'lifetime',
            price,
            payment: paymentMethodName,
            card_type: cardBrand,
            order_id: order_id,
            screen_id: payload?.customId || 'upsale',
            ab_test_name: abTestName,
            user_id: userId,
            urlParams,
            order_type: paymentType,
            ...(isSubscription(paymentType) && {
                subscription_id: subscriptionId,
                subscription_price: price,
            }),
        };

        Main.customData('purch_pending', cleanObject(analyticData));
        Yahoo.push({ et: 'custom', ea: 'purch_pending', gv: String(analyticData.value) });
    } catch (e) {
        console.error('sendAnalyticPurchasePending', e);
    }
}

function* moveToNextPage(validateStatus: boolean, payload: ToNextPagePayload) {
    yield call(payload.toNextPage, validateStatus);
}

function* showErrorModal(isOpen: boolean) {
    yield put(showModal(isOpen));
}

function* errorHandler() {
    yield call(setUpsellId, '100');
}

function* setUpsellId(id: string) {
    yield put({ type: actionTypes.SET_UPSELL_PRODUCT_ID, payload: id });
}

export const initOneClickPayment = [takeLatest(actionTypes.INIT_ONE_CLICK_PAYMENT, init)];

export const oneClickPaymentTestData = {
    setUpsellId,
    showErrorModal,
    moveToNextPage,
    errorHandler,
};
