import UserApi from './components/User';
import PaymentApi from './components/Payment';
import OnboardingApi from './components/Onboarding';

function ApiInstance() {
    const user = new UserApi();
    const payment = new PaymentApi();
    const onboarding = new OnboardingApi();

    return {
        user,
        payment,
        onboarding,
    };
}

export default ApiInstance();
