import { put, call, takeLatest } from 'redux-saga/effects';

import api from 'api';

import { UPDATE_SENDPULS_MAILING } from 'redux/User/actionTypes';
import { setLoader, updateSendpulseMailing } from 'redux/User/actions';

function* updateSendpulse({ payload }: ReturnType<typeof updateSendpulseMailing>) {
    const { data, toNextPage } = payload;

    try {
        yield put(setLoader(true));

        yield api.user.update(data);

        yield put(setLoader(false));

        yield call(toNextPage);
    } catch ({ error }) {
        yield put(setLoader(false));

        console.error(error);
    }
}

export const updateSendpulseMailingSaga = [takeLatest(UPDATE_SENDPULS_MAILING, updateSendpulse)];
