import { ClientError } from 'welltech-web-api-client';
import Solid from 'solid-payment';
import { ERROR_LEVELS, SENTRY_AXIOS, SENTRY_AXIOS_URGENT } from 'sentry-utils';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import { getUserSignature, getFrontHashSignature } from 'api/constants/urls';

import { selectIsPaid } from 'redux/User/selectors';
import { selectIsPaymentScreen } from 'redux/Payment/selectors';

import { APP_FULL_ACCESS, PAYMENT_PURCHASE_ERROR } from 'constants/payments';

import sentry from 'services/Sentry/SentryInstance';
import ApiClient from 'services/ApiClient';

import { deleteConfig } from 'helpers/utils';

import PurchaseErrorModal from 'modals/PurchaseErrorModal';
import GeneralErrorModal from 'modals/GeneralErrorModal';

import { SolidApiError } from '../types/errors';

const ErrorHandler = () => {
    const [isOpenGeneralErrorModal, setIsOpenGeneralErrorModal] = useState(false);
    const [isOpenPurchaseErrorModal, setIsOpenPurchaseErrorModal] = useState(false);

    const [productCode, setProductCode] = useState(APP_FULL_ACCESS);

    const isPaidUser = useSelector(selectIsPaid);
    const isPaymentScreen = useSelector(selectIsPaymentScreen);

    const handleErrorStatus = (error: ClientError | SolidApiError | Error) => {
        const errorRequest = 'request' in error && error?.request;
        const errorResponse = 'response' in error && error?.response;

        const responseConfig = errorResponse && errorResponse?.config;
        const responseURL = errorResponse && errorResponse?.config?.url;
        const responseData = errorResponse && errorResponse?.data;
        const status = errorResponse && errorResponse?.status;

        const isGetUserBySignatureMethod = responseURL && responseURL?.startsWith(`/${getUserSignature}`);
        const isGetFrontHashSignatureMethod = responseURL && responseURL?.startsWith(`/${getFrontHashSignature}`);

        const isExclusionEndpoint = isGetFrontHashSignatureMethod || isGetUserBySignatureMethod;

        if (status === 403 && !isExclusionEndpoint) {
            deleteConfig();

            if (location.search) history.pushState(null, '', location.origin);

            location.reload();
        }

        if (status === 409 && responseData?.message === PAYMENT_PURCHASE_ERROR) {
            const requestData = JSON.parse(responseConfig && responseConfig?.data);

            isPaymentScreen && sentry.logError(new Error('PURCHASE ERROR MODAL IS OPEN'), SENTRY_AXIOS, ERROR_LEVELS.ERROR, {
                productCode: requestData?.product_code,
                ...errorRequest,
                ...errorResponse
            });

            requestData?.product_code && setProductCode(requestData?.product_code);
            setIsOpenPurchaseErrorModal(true);
        }

        if (status === 409 && isPaymentScreen && !isPaidUser) {
            sentry.logError(error, SENTRY_AXIOS_URGENT, ERROR_LEVELS.CRITICAL, { ...errorRequest, ...errorResponse });
        }

        if (status === 500 || status === 404) {
            setIsOpenGeneralErrorModal(true);
        }

        if (status && status >= 500) {
            sentry.logError(error, SENTRY_AXIOS, ERROR_LEVELS.CRITICAL, { ...errorRequest, ...errorResponse });
        }
    };

    useEffect(() => {
        const cancelErrorHandler = ApiClient.onError((error) => {
            handleErrorStatus(error);
        });

        Solid.onError((error: ClientError) => {
            handleErrorStatus(error);
        });

        return cancelErrorHandler;
    }, []);

    return (
        <>
            {isOpenGeneralErrorModal && <GeneralErrorModal isOpenModal closeHandler={() => setIsOpenGeneralErrorModal(false)} />}
            {isOpenPurchaseErrorModal && (
                <PurchaseErrorModal
                    isOpen
                    productCode={productCode}
                    closeHandler={() => setIsOpenPurchaseErrorModal(false)}
                />
            )}
        </>
    );
};

export default ErrorHandler;
