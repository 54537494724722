import { ERROR_LEVELS, SENTRY_TESTANIA } from 'sentry-utils';

import api from 'api';

import { setIsPaid, setUserData } from 'redux/User/actions';
import { clearCompleteOBs, setCompleteOBs, setCurrenyBranchName, setTestaniaLoginStatus } from 'redux/Testania/actions';
import { setMeasure } from 'redux/Onboadring/actions';

import { onboardingScreensWithoutProgressBar } from 'constants/onboardingScreensWithoutProgressBar';
import { COUNTRIES_IMPERIAL_SYSTEM, COUNTRIES_LIST } from 'constants/countriesData';

import sentry from 'services/Sentry/SentryInstance';

import { replaceUnderscore, checkRequiredFields } from 'helpers/utils';
import { isEmptyValue } from 'helpers/root/utils';

export const getIsLogin = async (testaniaLoginStatus, token) => {
    return testaniaLoginStatus === 'true' || !!token || false;
};

export const getScreenList = (onboardingFlow) => {
    let count = 1;

    return onboardingFlow.reduce((screenList, { id }) => {
        const onboardingFlowPage = replaceUnderscore(id);

        if (onboardingScreensWithoutProgressBar.includes(onboardingFlowPage)) {
            return screenList;
        }

        return { ...screenList, [onboardingFlowPage]: { index: count++ } };
    }, {});
};

export const getBranchNameFromLocalStorage = () => {
    return localStorage.getItem('config') && JSON.parse(localStorage.getItem('config'))?.branch_name;
};

export const getConfigBy = (name, defaultValue) => {
    const params = new URLSearchParams(window.location.search);

    return params.get(name) || getBranchNameFromLocalStorage() || defaultValue;
};

export const getVariantBranchName = () => {
    const params = new URLSearchParams(window.location.search);

    return params.get('variant-name');
};

const getUserSetData = async (dispatch) => {
    let user = {};

    try {
        user = await api.user.getUser();
        checkRequiredFields(user);

        !isEmptyValue(user) && dispatch(setUserData(user));
    } catch ({ error }) {
        sentry.logError(error, SENTRY_TESTANIA, ERROR_LEVELS.ERROR);
    }

    return { isPaid: Boolean(user?.is_paid), isPasswordSet: Boolean(user?.is_password) };
};

export const getUserStatus = async (dispatch, token) => {
    const userStatus = token ? await getUserSetData(dispatch) : { isPaid: false, isPasswordSet: false };

    dispatch(setIsPaid(userStatus?.isPaid));

    return userStatus;
};

export const setCurrentBranchToStore = async (branch, dispatch) => {
    dispatch(setCurrenyBranchName(branch));
};

export const setCompleteOBsToStore = async (branch, dispatch) => {
    dispatch(setCompleteOBs(branch));
};

export const clearCompleteOBsToStore = async (dispatch) => {
    dispatch(clearCompleteOBs());
};

export const setTestaniaLoginStatusToStore = async (status, dispatch) => {
    dispatch(setTestaniaLoginStatus(status));
};

export const setInitMeasure = (country, dispatch) => {
    dispatch(setMeasure(COUNTRIES_IMPERIAL_SYSTEM.includes(country) ? 'IMPERIAL' : 'METRIC'));
};

export const validateCountry = (country) => {
    const countryCodeList = COUNTRIES_LIST.map((el) => el.code);

    return Boolean(typeof country === 'string' && countryCodeList.includes(country));
};
