import MobileApiClient from 'services/MobileApiClient';

import { PayloadExtraWorkoutTypes, ResponseExtraWorkoutTypes } from 'types/onboarding/extraWorkoutType';
import { ResponsePlanDynamicConfigs } from 'types/onboarding/dynamicConfig';

class OnboardingApi {
    async getPlanDynamicConfigs<Response = ResponsePlanDynamicConfigs>(): Promise<Response | unknown> {
        return await MobileApiClient.get<Response>('/plan-dynamic-configs', undefined, { timeout: 7000 });
    }

    async getExtraWorkoutType<Response = ResponseExtraWorkoutTypes>({ params }: PayloadExtraWorkoutTypes): Promise<Response | unknown> {
        return await MobileApiClient.get<Response>('/extra-workout-type', params);
    }
}

export default OnboardingApi;
