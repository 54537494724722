import { ERROR_LEVELS, SENTRY_APP } from 'sentry-utils';
import { put, takeLatest } from 'redux-saga/effects';

import api from 'api';

import { GET_PLAN_DYNAMIC_CONFIGS } from 'redux/Onboadring/actionTypes';
import { setDynamicConfigs } from 'redux/Onboadring/actions';

import sentry from 'services/Sentry/SentryInstance';

import { ResponsePlanDynamicConfigs } from 'types/onboarding/dynamicConfig';

function* dynamicConfigs() {
    try {
        const data: ResponsePlanDynamicConfigs = yield api.onboarding.getPlanDynamicConfigs();

        if (!data.data) {
            sentry.logError(new Error('User can\'t get dynamicConfig DATA | Personalization Plan'), SENTRY_APP, ERROR_LEVELS.CRITICAL);
        }

        yield put(setDynamicConfigs(data.data || []));
    } catch (error) {
        if (error?.code !== 'ERR_NETWORK') {
            sentry.logError(new Error('User can\'t get dynamicConfig | Personalization Plan'), SENTRY_APP, ERROR_LEVELS.CRITICAL, { info: error });
        }

        console.error('error', error);
    }
}

export const planDynamicConfigs = [takeLatest(GET_PLAN_DYNAMIC_CONFIGS, dynamicConfigs)];
