import { initApiClient, ClientError } from 'welltech-web-api-client';
import { ERROR_LEVELS, SENTRY_AXIOS } from 'sentry-utils';

import config from 'config';

import sentry from 'services/Sentry/SentryInstance';

import { deleteConfig } from 'helpers/utils';

const TOKEN_KEY = 'token';

function ApiClient() {
    return initApiClient({
        apiUrl: config.API_URL,
        apiVersion: config.API_VERSION,
        apiKey: config.API_KEY,
        authTokenKey: TOKEN_KEY,
        retries: 3,
        timeout: 40000,
        extendResponse: false,
        onUpdateToken: (token) => {
            localStorage.setItem(TOKEN_KEY, token);
        },
        onRetry: (retryCount, error, requestConfig) => {
            sentry.logError(new Error(`RETRY: ${error.message}`), SENTRY_AXIOS, ERROR_LEVELS.WARNING, {
                ...requestConfig
            });
        },
        onAuthorisationError: (error: ClientError) => {
            const errorStatus = error?.response?.status;

            if (errorStatus === 403) {
                deleteConfig();

                location.replace('/');
            }

        },

        onErrorHandler: () => {},
    });
}

export default ApiClient();

