import { uaParser } from 'wikr-core-analytics';
import { ClientError } from 'welltech-web-api-client';
import { SENTRY_AXIOS } from 'sentry-utils';
import { Event } from '@sentry/types';

import { objectEntriesPolyfill } from 'helpers/polyfills';

import { Extras, ErrorType, Tags, IError } from './types';

const IOS = 'iOS';
const ANDROID = 'Android';
const CHROME = 'Chrome';
const IOS_MIN_VERSION = 14.3;
const ANDROID_MIN_VERSION = 7;
const CHROME_MIN_VERSION = 59;

export const getCustomTags = (error: IError | ClientError, type: ErrorType, extras?: Extras): Tags => {
    const customTags: Record<string, string> = {};

    if (type === SENTRY_AXIOS) {
        customTags.API_ERROR_CODE = extras?.status as string;
    }

    return objectEntriesPolyfill(customTags);
};

export const checkIsOldDevices = (event: Event) => {
    const UAParser = new uaParser();
    const getUserAgent = event && event.extra && event.extra.agent;
    const userAgent = UAParser.setUA(getUserAgent);

    const browser = userAgent && userAgent.getBrowser();
    const browserMajor = browser && browser.major;
    const browserName = browser && browser.name;

    const deviceOS = userAgent && userAgent.getOS();
    const deviceOSVersion = deviceOS && deviceOS.version;
    const deviceOSName = deviceOS && deviceOS.name;

    const parsedOSVersion = parseFloat(deviceOSVersion || '');
    const parsedBrowserVersion = parseFloat(browserMajor || '');

    return (
        (deviceOSName === IOS && parsedOSVersion < IOS_MIN_VERSION) ||
        (deviceOSName === ANDROID && parsedOSVersion < ANDROID_MIN_VERSION) ||
        (browserName === CHROME && parsedBrowserVersion < CHROME_MIN_VERSION)
    );
};
