import { If } from 'tsx-control-statements/components';
import { Trans } from 'react-i18next';
import React, { useEffect, ReactElement } from 'react';
import classNames from 'classnames/bind';

import { isFirstMirror } from 'helpers/settings';

import Loader from 'components/Loader';

import base from 'assets/styles/Common.module.css';
import stylesFirstMirror from './ButtonFirstMirror.module.css';
import styles from './Button.module.css';

import Arrow from './img/arrow.svg';

import { IGlobalStyles, IButtonProps } from './types';

const buttonStyles = isFirstMirror() ? stylesFirstMirror : styles;

const globalStyles: IGlobalStyles = { ...buttonStyles, ...base };
const cx = classNames.bind(globalStyles);

function Button(props: IButtonProps): ReactElement {
    const {
        globalClassNames,
        title,
        onClick,
        inverse,
        animation,
        animationFast,
        primary,
        female,
        male,
        rounded,
        small,
        disabled,
        isLoading,
        type,
        bold,
        arrowed,
        dataLocator,
        isOnePrice,
        squareBtn,
        notificationBtn,
        pulse,
        btnConsent,
        femaleFast,
        maleFast,
        noMargin,
        exclusive,
        skip,
        withBorder,
        customClassName,
        small2,
    } = props;

    const classes = globalClassNames && globalClassNames.map((item) => ({ [item]: globalStyles[item] }));
    const btnClass = cx(
        {
            default: true,
            primary,
            animation,
            animationFast,
            inverse,
            female,
            male,
            rounded,
            small,
            bold,
            arrowedLeft: arrowed === 'left',
            arrowedRight: arrowed === 'right',
            isOnePrice: isOnePrice,
            squareBtn: squareBtn,
            notificationBtn: notificationBtn,
            pulse,
            btnConsent: btnConsent,
            femaleFast,
            maleFast,
            noMargin,
            exclusive,
            skip,
            withBorder,
            small2,
        },
        pulse,
        classes,
        customClassName
    );

    useEffect(() => {
        if (type === 'submit') {
            const handleSubmit = (e: KeyboardEvent) => {
                if (e.code === 'Enter') handleClick();
            };

            window.addEventListener('keypress', handleSubmit);

            return () => {
                window.removeEventListener('keypress', handleSubmit);
            };
        }
    }, []);

    const handleClick = () => {
        onClick && onClick();
    };

    return (
        <button
            data-locator={dataLocator}
            type={type ?? 'button'}
            disabled={disabled || isLoading}
            className={btnClass}
            onClick={handleClick}
        >
            <>
                <Trans>{title}</Trans>
                <If condition={isLoading}>
                    <Loader />
                </If>
                <If condition={arrowed}>
                    <Arrow className={styles.arrow} />
                </If>
            </>
        </button>
    );
}

export default Button;
