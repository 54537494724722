import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import React from 'react';
import classNames from 'classnames/bind';

import { selectHideProgressBar } from 'redux/UiEffects/selectors';
import { selectOnboardingScreensList } from 'redux/Onboadring/selectors';

import { Icon } from 'libComponents';
import Logo from 'components/Logo';

import styles from './Header.module.css';

import BackIcon from './img/icon-back.svg';

import { IHeaderProps } from './types';

const cx = classNames.bind(styles);

const Header = (props: IHeaderProps) => {
    const { centered, onSkipClick, dataLocator, history } = props;

    const headerClass = cx({
        header: true,
    });

    const onboardingScreensList = useSelector(selectOnboardingScreensList);
    const isHideProgressBar = useSelector(selectHideProgressBar);

    const currentLocationName = location.pathname.replace(/(\/([a-z]{2})\/)|\//g, '');
    const currentPageCount = onboardingScreensList[currentLocationName]?.index;
    const screensNumber = Object.keys(onboardingScreensList).length;

    const calculateProgressWidth = () => (currentPageCount / screensNumber) * 100 + '%';

    const onboardingHeader = onboardingScreensList[currentLocationName] && !isHideProgressBar;

    const headerContent = () => {
        return (
            <div className={styles.headerContainer}>
                <div className={styles.top}>
                    {onboardingHeader && (
                        <div onClick={() => history.goBack()}>
                            <BackIcon className={styles.iconBack} />
                        </div>
                    )}
                    <Logo
                        isOnboarding={onboardingScreensList[currentLocationName]}
                        centered={centered}
                        onboardingLogo={onboardingHeader}
                    />
                    {onboardingHeader && (
                        <div className={styles.pageCounter}>
                            <span className={styles.pageStart}>{currentPageCount}</span>
                            <span className={styles.pageSeparator}>/</span>
                            <span className={styles.pageEnd}>{screensNumber}</span>
                        </div>
                    )}
                </div>
                {onboardingHeader && (
                    <div className={styles.progressBox}>
                        <div className={styles.progressBar} style={{ width: calculateProgressWidth() }} />
                    </div>
                )}
                {onSkipClick && (
                    <button onClick={onSkipClick} className={styles.skipButton} data-locator={dataLocator}>
                        <Trans>skip</Trans>
                        <Icon name="arrowRight" libColor="text-secondary-subdued" />
                    </button>
                )}
            </div>
        );
    };

    return <header className={headerClass}>{headerContent()}</header>;
};

export default withRouter(Header);
