import { InitPaymentGenerator } from 'solid-payment/src/interfaces/payment';
import Solid from 'solid-payment';
import { SENTRY_PAYMENT, ERROR_LEVELS } from 'sentry-utils';
import { takeLatest, put } from 'redux-saga/effects';
import { InitConfig } from '@solidgate/client-sdk-loader';

import * as actionTypes from 'redux/Payment/actionTypes';
import { setMerchantData } from 'redux/Payment/actions';

import sentry from 'services/Sentry/SentryInstance';

import { ActionType } from 'types/commonInterfaces';

type InitResponse = InitConfig['merchantData'] | { message: string };

export function* init({ payload }: ActionType<InitPaymentGenerator>) {
    try {
        yield put({ type: actionTypes.SET_LOADING_STATUS, payload: true });

        const response: InitResponse = yield Solid.init('paymentGenerator', payload);

        yield put({ type: actionTypes.SET_LOADING_STATUS, payload: false });

        yield put(setMerchantData(response as InitConfig['merchantData']));
    } catch (e) {
        console.error('Init payment error', e);

        sentry.logError(e, SENTRY_PAYMENT, ERROR_LEVELS.CRITICAL, {
            ...payload
        });
    }
}

export const initPaymentGenerator = [takeLatest(actionTypes.INIT_PAYMENT_GENERATOR, init)];
