import { all, put, takeLatest } from 'redux-saga/effects';

import config from 'config';

import api from 'api';

import { setIsAutologinComplete, setToken, setUserEmail, setUserId, setIsPaid, setUserName } from 'redux/User/actions';
import { INIT_AUTO_LOGIN } from 'redux/Onboadring/actionTypes';
import {
    setGoal,
    setGender,
    setCurrentWeight,
    setTargetWeight,
    setTall,
    setMeasure,
    setTargetBody,
    setCurrentBody,
    setAge,
    setTrainingLevel,
} from 'redux/Onboadring/actions';

import sentry from 'services/Sentry/SentryInstance';

import { getAgeFromBirthday, getParamFromUrl, checkRequiredFields } from 'helpers/utils';
import { getCurrentLocalization } from 'helpers/settings';

import { IGetUserResponse } from 'types/user/getUser';
import { MeasuringSystemType } from 'types/onboarding/onboarding';

function* setUserProfileFromEmail() {
    const token = getParamFromUrl('token');

    if (!token) {
        return;
    }

    try {
        const localization = getCurrentLocalization();

        localStorage.setItem('language', localization);

        yield put(setToken(token));

        const userData: IGetUserResponse = yield api.user.getUser();

        checkRequiredFields(userData);

        const {
            age,
            email,
            user_id,
            units,
            is_password,
            goal,
            gender,
            is_paid,
            weight,
            target_weight,
            target_bodytype,
            current_bodytype,
            height,
            name,
            last_name,
            fitness_level,
        } = userData;

        if (is_paid && is_password) {
            localStorage.clear();
            yield put({ type: 'RESET_STATE' });

            window.location.href = `${config.USER_CABINET_URL}/?token=${token}`;
        }

        sentry.setUser({ email, user_id });

        const measureSystem = units?.toUpperCase?.() || 'METRIC';
        const userAge = getAgeFromBirthday(age);

        yield all([
            put(setUserEmail(email)),
            put(setUserId(user_id)),
            put(setIsAutologinComplete(true)),
            put(setGoal(goal)),
            put(setGender(gender)),
            put(setTrainingLevel(fitness_level)),
            put(setIsPaid(is_paid)),
            put(setMeasure(<MeasuringSystemType>measureSystem)),
            put(setCurrentWeight({ unit: measureSystem, value: weight })),
            put(setTargetWeight({ unit: measureSystem, value: target_weight })),
            put(setTall({ unit: measureSystem, value: height })),
            put(setTargetBody(target_bodytype)),
            put(setCurrentBody(current_bodytype)),
            put(setUserName({ firstName: name, lastName: last_name })),
            put(setAge(userAge || 0)),
        ]);
    } catch (error) {
        console.error(error);

        yield put(setIsAutologinComplete(true));
    }
}

export const setUserProfile = [takeLatest(INIT_AUTO_LOGIN, setUserProfileFromEmail)];
