import ApiClient from 'services/ApiClient';

import { ValidateResponse, PostInitResponse } from 'types/payments/validate';
import {
    IPaymentFailInfo,
    IPaymentFailInfoResponse,
    ValidateData,
    PostInitData,
} from 'types/payments/paymentApiInterfaces';

class UserApi {
    async validate<Data = ValidateData, Response = ValidateResponse>(payload: Data): Promise<Response | unknown> {
        return await ApiClient.post<Data, Response>('payments/validate', payload);
    }

    async postInit<Data = PostInitData, Response = PostInitResponse>(payload: Data): Promise<Response | unknown> {
        return await ApiClient.post<Data, Response>('payments/post-init', payload);
    }

    async sendPaymentFailInfo<Data = IPaymentFailInfo, Response = IPaymentFailInfoResponse>(
        payload: Data
    ): Promise<Partial<Response> | unknown> {
        return await ApiClient.post<Data, Response>('reteno/event', payload);
    }
}

export default UserApi;
