import React from 'react';

import styles from './Styles.module.css';

import SvgLogo from 'assets/img/logo/logo.svg';

const LoaderSuspense = () => (
    <div className={styles.loaderWrapper}>
        <SvgLogo width="120" height={undefined} viewBox="0 0 21 20" className={styles.logo} />
    </div>
);

export default LoaderSuspense;
