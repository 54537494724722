import ApiClient from 'services/ApiClient';

import { SignUpData, UserInfoResponse, UserUpdateData, HashedEmailResponse } from 'types/user/userApiInterface';

class UserApi {
    async getUser<Response = UserInfoResponse>(): Promise<Partial<Response> | unknown> {
        return await ApiClient.get<Response>('user/');
    }

    async update<Data = UserUpdateData, Response = UserInfoResponse>(
        payload: Data
    ): Promise<Partial<Response> | unknown> {
        return await ApiClient.patch<Data, Response>('user/', payload);
    }

    async signUp<Data = SignUpData, Response = UserInfoResponse>(payload: Data): Promise<Partial<Response> | unknown> {
        return await ApiClient.post<Data, Response>('sign-up/', payload);
    }

    async hashedEmail() {
        return await ApiClient.get<HashedEmailResponse>('front-hash');
    }
}

export default UserApi;
