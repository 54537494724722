import { put, select, takeLatest } from 'redux-saga/effects';

import api from 'api';

import { selectGender, selectGoalId } from 'redux/Onboadring/selectors';
import { GET_EXTRA_WORKOUT_TYPE } from 'redux/Onboadring/actionTypes';
import { setExtraWorkoutType } from 'redux/Onboadring/actions';

import { ResponseExtraWorkoutTypes } from 'types/onboarding/extraWorkoutType';
import { IGender } from 'types/commonInterfaces';

function* extraWorkoutType() {
    const gender: IGender = yield select(selectGender);
    const goalId: number | undefined = yield select(selectGoalId);

    try {
        if (goalId) {
            const data: ResponseExtraWorkoutTypes = yield api.onboarding.getExtraWorkoutType({
                params: {
                    gender,
                    goal_id: goalId,
                },
            });

            yield put(setExtraWorkoutType(data.data || []));
        }
    } catch (error) {
        console.error('error', error);
    }
}

export const extraWorkoutTypeSaga = [takeLatest(GET_EXTRA_WORKOUT_TYPE, extraWorkoutType)];
