import { takeLatest } from 'redux-saga/effects';

import api from 'api';

import * as actionTypes from 'redux/Payment/actionTypes';

export function* sendPaymentFailInfoToReteno({ payload }: ReturnType<any>) {
    try {
        yield api.payment.sendPaymentFailInfo(payload);
    } catch ({ error }) {
        console.warn(error);
    }
}

export const sendPaymentFailInfo = [takeLatest(actionTypes.SET_PAYMENT_FAIL_INFO, sendPaymentFailInfoToReteno)];
