import { ERROR_LEVELS, SENTRY_AXIOS } from 'sentry-utils';
import { select, takeLatest } from 'redux-saga/effects';

import config from 'config';

import api from 'api';

import { selectInitFrontChat } from 'redux/UiEffects/selectors';
import { INIT_FRONT_CHAT } from 'redux/UiEffects/actionTypes';
import { selectPaymentMethod } from 'redux/Payment/selectors';

import sentry from 'services/Sentry/SentryInstance';

import { cleanObject, getLanguage, checkRequiredFields } from 'helpers/utils';

import { HashedEmailResponse } from 'types/user/userApiInterface';
import { IGetUserResponse } from 'types/user/getUser';
import { PaymentMethod } from 'types/payments/payments';

function* frontChatInitialization() {
    const language = getLanguage();

    const shouldInitChat: boolean = yield select(selectInitFrontChat);
    const paymentMethod: PaymentMethod = yield select(selectPaymentMethod);

    const isHideFrontChatValue = config?.HIDE_FRONT_CHAT || '0';
    const isHideFrontChat = !!JSON.parse(isHideFrontChatValue);

    window.HIDE_FRONT_CHAT = Number(isHideFrontChatValue);

    try {
        if (shouldInitChat && !isHideFrontChat) {
            const response: HashedEmailResponse = yield api.user.hashedEmail();
            const userData: IGetUserResponse = yield api.user.getUser();

            const { email, name, country, is_paid: isPaid, product_list } = userData;

            checkRequiredFields(userData);

            const customFields = cleanObject({
                platform: 'web',
                project: 'mycoach',
                country,
                language,
                isPaid,
                paymentType: paymentMethod,
                ...product_list,
            });

            const initData = {
                chatId: config?.FRONT_CHAT_ID,
                useDefaultLauncher: true,
                email: email?.toLowerCase(),
                name,
                userHash: response?.data,
                customFields: customFields,
            };

            if (!window.FrontChat) {
                const insertScript = () => {
                    const d = document;
                    const script = d.createElement('script');
                    script.type = 'text/javascript';
                    script.async = true;
                    script.src = 'https://chat-assets.frontapp.com/v1/chat.bundle.js';
                    d.body.appendChild(script);
                    script.onload = () => {
                        window.FrontChat('init', initData);
                    };
                };

                insertScript();
            }
        }

        if (window.FrontChat && !shouldInitChat) {
            window.FrontChat('shutdown', { clearSession: true });
        }
    } catch (error) {
        console.error('chat error', error);

        sentry.logError(error, SENTRY_AXIOS, ERROR_LEVELS.CRITICAL);
    }
}

export const frontChat = [takeLatest(INIT_FRONT_CHAT, frontChatInitialization)];
