import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware, compose } from 'redux';

import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { createBlacklistFilter } from 'redux-persist-transform-filter';
import { persistStore, persistReducer } from 'redux-persist';

import { DEV_PARAM_VALUE, DEV_PARAM_KEY } from 'constants/dev';

import config from './config';

import sagas from './redux/sagas';
import rootReducer from './redux/rootReducer';

// Remove merchantData field from persist store
const saveMerchantDataBlacklistFilter = createBlacklistFilter('payment', ['merchantData']);

const persistConfig = {
    key: 'root',
    storage,
    transforms: [saveMerchantDataBlacklistFilter],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();

export default function configureStore() {
    const composeEnhancers = (config.ENV !== 'prod' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

    const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(sagaMiddleware, thunk)));
    const persistor = persistStore(store);

    const urlParams = new URLSearchParams(window.location.search);
    const refParams = urlParams.get(DEV_PARAM_KEY);

    if (refParams === DEV_PARAM_VALUE || localStorage.getItem(DEV_PARAM_KEY)) {
        localStorage.setItem(DEV_PARAM_KEY, DEV_PARAM_VALUE);

        window['__getState'] = () => store.getState();
    }

    sagaMiddleware.run(sagas);

    return { store, persistor };
}
